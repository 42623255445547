@mixin global-custom-styles() {
  html {
    font-size: 16px;
  }

  * {
    scroll-behavior: smooth;
  }

  p {
    margin-bottom: 0;
  }

  nb-spinner {
    z-index: 999 !important;
  }

  .dialog-animate {
    animation-name: opacity-fill;
    animation-duration: 0.5s;
    max-width: 100vw;
  }

  .dialog-animate.is-close {
    animation-name: opacity-back;
    animation-duration: 0.3s;
  }

  @keyframes opacity-fill {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes opacity-back {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .hide {
    max-height: 0 !important;
    max-width: 0 !important;
    visibility: hidden;
    z-index: -1000;
  }

  .text-end {
    text-align: end;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .nowrap {
    white-space: nowrap;
  }

  .table {
    color: nb-theme(--table-row-text-color);

    thead::after {
      background-color: var(--card-background-color);
    }

    th {
      color: var(--table-header-color);

      &.sort:hover {
        color: var(--table-header-color-hover);
      }

      &.sort-asc::after,
      &.sort-desc::after {
        border-bottom-color: var(--table-header-color) !important;
      }
    }

    td {
      font-weight: 600;
    }

    td .sub-row {
      font-weight: 600;
      color: nb-theme(--table-sub-row-text-color);
    }
  }

  nb-layout.window-mode {
    padding-top: 0 !important;

    nb-sidebar {
      transition: width 0.5s ease;
    }

    > .scrollable-container {
      max-width: unset;
      height: 100vh;
    }

    nb-layout-header.fixed {
      top: 0 !important;
      max-width: unset;

      > nav {
        padding: 1rem;
        max-width: unset;
      }
    }
  }

  .ellipsis-text {
    &-10,
    &-15,
    &-20,
    &-30 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-10 {
      max-width: 6rem;
    }

    &-15 {
      max-width: 8.5rem;
    }

    &-20 {
      max-width: 11rem;
    }

    &-30 {
      max-width: 19rem;
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }

  .path-fill-gray path {
    fill: #afafaf;
  }

  .invalid nb-select button {
    border-color: #ff0606 !important;
  }

  .tinymce-inline .mce-content-body {
    border-color: nb-theme(border-basic-color-4) !important;
    background-color: nb-theme(input-basic-background-color);
    transition: background-color 0.3s ease;

    &:focus-visible {
      outline: nb-theme(input-basic-focus-border-color) 1px solid;
      background-color: nb-theme(input-basic-focus-background-color);
      border-color: transparent;

      &:hover {
        background-color: nb-theme(input-basic-focus-and-hover-background-color);
        border-color: transparent;
        outline: nb-theme(input-basic-focus-and-hover-border-color) 1px solid;
      }
    }
  }
}
