@mixin forms-styles() {
  button {
    &:not(.action-btn) {
      background: none;
      outline: none;
      border: none;
      border-color: unset;
      padding: 0;
      margin: 0;
    }

    &.action-btn {
      padding: 0.3rem !important;
    }

    i {
      font-size: 1.5rem;
    }

    &:hover {
      color: nb-theme(color-primary-default);

      svg path {
        fill: #000ef2;
      }
    }
  }

  nb-select[ng-reflect-multiple] button nb-icon {
    display: none;
  }

  .input-label {
    font-weight: 600;
  }

  label.required::after {
    content: '*';
    position: relative;
    font-size: inherit;
    color: #f00;
    padding-left: 0.25rem;
    font-weight: 600;
  }

  .invalid input {
    border-color: #ff0606 !important;
  }

  .input-group-custom {
    .error-message {
      overflow: hidden;
      opacity: 0;
      max-height: 0;
      max-width: 0;
      transition: max-height 0.5s ease;

      p {
        margin: 0;
        color: #ff0606;
      }
    }

    &.invalid {
      input,
      textarea {
        border-color: #ff0606 !important;
      }

      .error-message {
        opacity: 1;
        max-height: fit-content;
        max-width: unset;
      }
    }
  }

  label.inline {
    white-space: nowrap;
    margin-bottom: 0;
  }
}
